@use 'proof-factor/import' as pf;
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: pf.$color-primary;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background: pf.$color-primary;
}

// .mat-checkbox {
//   label.mat-checkbox-layout {
//     margin-bottom: 0;
//   }
// }
.mat-mdc-checkbox {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #0095f7;
  --mdc-checkbox-selected-hover-icon-color: #0095f7;
  --mdc-checkbox-selected-icon-color: #0095f7;
  --mdc-checkbox-selected-pressed-icon-color: #0095f7;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0095f7;
  --mdc-checkbox-selected-hover-state-layer-color: #0095f7;
  --mdc-checkbox-selected-pressed-state-layer-color: #0095f7;
  --mdc-checkbox-unselected-focus-state-layer-color: transparent;
  --mdc-checkbox-unselected-hover-state-layer-color: transparent;
  --mdc-checkbox-unselected-pressed-state-layer-color: transparent;
  .mdc-label {
    margin-bottom: 0;
  }


}
