@use 'proof-factor/import' as pf;
.Logo {
  margin-right: 20px;
  cursor: default;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: 36px;
  line-height: var(--header-height);
  font-weight: 500;
  text-transform: lowercase;

  &-Text {
    color: #bdc0c1;

    &._highlighted {
      color: #3f80ec;
    }

    display: none;
    //@include breakpoint-sm-alt {
    //    //  display: inline;
    //    //}
  }

  &-Image {
    display: block;
    height: var(--header-height) - 10px * 2;
    margin-top: 10px;
    //@include breakpoint-sm-alt {
    //  display: none;
    //}

    &._one {
      height: 60px;
      margin-top: 0;
    }
  }
}
