one-icon {
  &._eye {
    border-radius: 5px;
    &:hover {
      background-color: var(--eye-icon-hover-background);
      color: var(--eye-icon-hover-text);
    }
    &:active {
      background-color: var(--eye-icon-active-background);
      color: var(--eye-icon-active-text);
    }
  }
}
