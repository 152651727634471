@keyframes progress {
  0% { background-position: -200px 0; }
  100% { background-position: calc(200px + 100%) 0; }
}

@mixin shimmerGeneralStyle {
  animation: 2s ease-in-out infinite progress;
  background: 0 0/200px 100% no-repeat #eff1f6;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
  border-radius: 4px;
}

$sizeStep: 5;
$maxSize: 800;

// @mixin w-h {
  // @for $i from 0 to $maxSize/$sizeStep {
    // .shimmer-mw-#{$i*$sizeStep} { min-width: #{$i*$sizeStep}px !important; }
    // .shimmer-mh-#{$i*$sizeStep} { min-height: #{$i*$sizeStep}px !important; }
  // }
// }

// @include w-h;

.shimmer-mh-160 {
  min-height: 160px;
}
.shimmer-mh-120 {
  min-height: 120px;
}
.shimmer-mh-225 {
  min-height: 225px;
}
.shimmer-mh-620 {
  min-height: 620px;
}
.shimmer-mw-100 {
  min-width: 100px;
}

.shimmer {
  &-line {
    @include shimmerGeneralStyle;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 14px;
    display: inline-block;
  }
  &-block {
      background: var(--separator);
  }
}
