@use 'proof-factor/import' as pf;

body {
  position: relative;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  @include pf.custom-scrollbar;
  input:focus {
    outline: none;
  }

  // nebular style, to be refactored
  a {
    color: #005c97;
    &:hover {
      color: #0095f7;
    }
  }

  h1, .h1 {
    font-size: 32px;
  }
  h2, .h2 {
    font-size: 28.8px;
  }
  h3, .h3 {
    font-size: 25.6px;
  }
  h4, .h4 {
    font-size: 22.4px;
  }
  h5, .h5 {
    font-size: 19.2px;
  }
  h6, .h6 {
    font-size: 16px;
  }
  &._in-full-screen {
    overflow: hidden;
  }
}



