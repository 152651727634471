@use 'proof-factor/import' as pf;

// General Layout
.EmailOnboarding-L {
  display: grid;
  width: 100%;
  position: relative;
  height: 100%;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr auto;
  gap: 10px;
  grid-template-areas: 'aside content'
                       'none footer';
  padding-left: 100px;
  transition: 0.3s ease-in-out;
  @include pf.breakpoint-600 {
    padding-left: 0;
    grid-template-rows: 190px 1fr 70px;
    grid-template-columns: 1fr;
    grid-template-areas: 'aside'
                         'content'
                         'footer';
  }



  &.sidebar-pinned {
    padding-left: 200px;
    @include pf.breakpoint-600 {
      padding-left: 0;
    }
  }

  &-Aside {
    position: absolute;
    @include pf.breakpoint-600 {
      position: relative;
    }
  }

  &-Content {
    position: relative;
    grid-area: content;
    place-self: start;
    overflow-y: auto;
    width: 100%;
    //min-width: 500px;
    // max-width: 900px;
    min-height: 50vh;
    max-height: 100%;
    margin: 0 auto;
    z-index: 1;
    @include pf.custom-scrollbar();
  }

  &-Footer {
    position: relative;
    grid-area: footer;
    width: 100%;
    z-index: 2;
    align-self: end;

    .Card {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      // max-width: 900px;
      margin: auto;
      padding: 10px 20px;
    }
    }
}

// Header Layout
.EmailOnboarding-L {
  &-Head {
    border-radius: 5px;
    border: 1px solid #ebeef2;
    background: #fff;
    box-shadow: 0 2px 12px 0 #dfe3eb;
    display: grid;
    grid-template-rows: 65px auto;
    padding: 0;

    h2 {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #ebeef2;
      color: var(--color-primary);
      margin: 0;
      padding: 20px;
      text-align: center;
    }

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 15px;
    }
  }
}

//  Config Card Layout
.EmailOnboarding-ConfigCard {
  border-radius: 1px;
  margin-bottom: 15px;
  overflow: hidden;
  box-shadow: 4px 4px 8px rgba(189, 136, 136, 0.08);

  &-Head {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding: 10px;
    color: #000;
    background-color: #fff;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    box-shadow: inset 0 0 0 #fff;
    transition: 0.2s;

    ion-icon {
      color: #000;
    }

    // &._hoverable {
    //   &:hover {
    //     background-color: #0095f7;
    //     color: #fff;
    //   }
    //   &:hover .card-control img {
    //     filter: brightness(0) saturate(100%) invert(100%)
    //     sepia(1%) saturate(2%) hue-rotate(174deg) brightness(119%) contrast(100%);
    //   }
    //   &:hover ._tooltip {
    //     color: #50d73f;
    //     background: #fff;
    //   }
    // }

    &._invert {
      color: var(--color-primary);
      box-shadow: inset 4px 0 0 var(--color-primary);
    }

    .card-control {
      display: flex;
      align-items: center;
      flex: 1;
      cursor: default;
      font-weight: bold;
      padding-left: 0.25rem;

      & ion-icon {
        font-size: 18px;
        line-height: 18px;
        margin-right: 10px;
        cursor: pointer;
        transition: all 0.15s;
        transform: rotate(-90deg);
        color: #000;
      }
      & ._rotated {
        color: var(--color-primary);
        transform: rotate(0deg);
      }

      &-tip {
        cursor: pointer;
        color: #000;

        ion-icon {
          font-size: 24px;
          margin-right: 5px;
          vertical-align: middle;
        }

        &._completed ion-icon {
          color: var(--color-success);
        }
        &._invalid ion-icon {
          color: var(--color-danger);
        }
      }

      &-invalid-tip {
        cursor: pointer;
        color: #000;
        ion-icon {
          font-size: 24px;
          margin-right: 5px;
          vertical-align: middle;
          color: var(--color-danger);
        }
      }
    }
  }

  &-Body {
    font-size: 14px;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    overflow: hidden;
    border: 1px solid #ebeef2;
    border-top: none;
    background: linear-gradient(180deg, #dfe3eb 0, #ffffff 8px);

    a {
      color: #0095f7;
      cursor: pointer;
    }
  }

  &-BodyLine {
    padding: 20px;
  }
}

.EmailOnboarding-CheckboxBlock {
  width: 80%;
  margin: 30px auto 0;
  padding: 30px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 6px 20px -3px rgba(15, 38, 52, 0.06), 0 3px 14px -2px rgba(15, 38, 52, 0.08);

  @include pf.breakpoint-600 {
    width: 100%;
  }
}

.EmailOnboarding-TextWithImageBlock {
  display: flex;
  align-items: center;
  width: 80%;
  margin: 0 auto;

  ul {
    margin-bottom: 0;
    padding-left: 20px;
  }
}

.EmailOnboarding-Radio-Item {
  flex: 0 1 100%;
  display: flex;
  align-items: baseline;
  position: relative;
  user-select: none;
  margin-left: 5px;


  input[type=radio] {
    position: absolute;
    opacity: 0;
  }

  .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid #bdc0c1;
    border-radius: 50%;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      display: none;
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: var(--color-primary);
    }
  }

  & input:checked ~ label .checkmark {
    border: 2px solid var(--color-primary);
  }
  & input:checked ~ label .checkmark::after {
    display: block;
  }

  label {
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100%;
    padding: 0 10px 10px 30px;
    color: #333333;
    background: #fff;
    transition: all 180ms;

  }


}
