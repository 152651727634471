.Resolve,
a.Resolve {
  background: var(--color-danger);
  padding: 5px 10px;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  font-size: 12px;
  font-weight: bold;
  margin: 5px;
  transition: 0.2s;
  border: 0;

  &:hover {
    background: #ff2b4e;
    color: #fff;
  }

  &._warn {
    background: var(--color-warning);

    &:hover {
      background: #e69100;
    }
  }

  &._primary {
    background: var(--color-primary);

    &:hover {
      background: #1567d3;
    }
  }
}
