@use 'proof-factor/import' as pf;

@mixin base-ng-select-styles {
  .option-item {

    &._disabled {
      opacity: 0.5;
      cursor: default;

      &:hover {
        background-color: #fff;
      }
    }

    &.email {
      display: flex;
      // justify-content: space-between;
      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: auto;
      }
      .label {
        border-radius: 30px;
        padding: 0 10px 2px;
        background: var(--color-primary-new);
        color: #fff;
        font-size: 12px;
        line-height: 19px;
        margin-left: 5px;

        &._red {
          background: var(--color-danger);
        }
      }
    }
    &.create {
      padding: 10px;
      color: var(--color-primary-new);
      cursor: pointer;
    }
  }

  .ng-select-container {
    cursor: pointer !important;
    .ng-value-container {
      .ng-value {
        width: 100%;
      }
    }
  }
  .ng-option {
    padding: 8px 10px !important;
    border-bottom: 1px solid var(--dark-separator);
  }
  .ng-dropdown-footer {
    padding: 0;
    &:hover {
      background-color: #f5faff;
    }
  }
}

.custom.ng-select {
  &._invalid .ng-select-container {
    border-color: #ff5151;
  }

  & .ng-arrow-wrapper {
    padding-right: 15px;
  }

  &.ng-select-opened > .ng-select-container {
    border-color: #dbe0e7;
  }

  &.ng-select-single .ng-select-container {
    height: 41px;
    @media screen and (max-width: 992px) {
      height: fit-content;
    }
  }

  .ng-select-container {
    border: 1px solid #bdc0c1;;
  }

  &.ng-touched.ng-invalid {
    .ng-select-container {
      border-color: #ff4c6a;
    }
  }
}

.thin-border.ng-select {
  .ng-select-container {
    border: 1px solid #ced4da;
  }
  .ng-placeholder {
    color: #6c757d !important;
  }
}

.ng-select.disabled {
  pointer-events: none;
  opacity: 0.7;
  .ng-select-container {
    background-color: #e9ecef;
    &:hover {
      cursor: not-allowed;
    }
  }
}

.ng-dropdown-panel-items {
  @include pf.custom-scrollbar;
}

.custom.ng-dropdown-panel,
.custom .ng-dropdown-panel {
  border: none;
  z-index: 1000;

  .ng-dropdown-panel-items .ng-option {
    padding: 4px 10px;
  }

  .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    cursor: initial;
  }

  .ng-dropdown-footer {
    border-top: 0;
    padding: 0;
  }

  .ng-dropdown-panel-items {
    border: 2px solid #dbe0e7;
    border-bottom: 0;
  }

  .ng-option.ng-option-selected {
    background-color: transparent;
  }

  .ng-option.ng-option-selected,
  .ng-option.ng-option-selected.ng-option-marked {

    .CouponCode-Item._expired {
      opacity: 0.5;
      background: #f6f6f7;
    }

    .CouponCode-Item {
      border: 1px solid #0095f7;
    }
  }

  .mat-paginator-range-actions {
    @include pf.breakpoint-1024() {
      width: 100%;
    }
  }
  .mat-paginator-range-label {
    @include pf.breakpoint-1024() {
      margin: 5px;
    }
    @include pf.breakpoint-540() {
      font-size: 10px;
    }
  }
}

._ng-select-custom-short {
  .custom.ng-dropdown-panel {
    .ng-dropdown-panel-items {
      max-height: 288px !important;
      @include pf.breakpoint-1400() {
        max-height: 300px !important;
      }
    }
  }
}

.ng-select.email-list,
.ng-dropdown-panel.email-list {
  @include base-ng-select-styles;
}

.broadcast-email-list,
.broadcast-email-list.ng-select,
.broadcast-email-list.ng-dropdown-panel {
  @include base-ng-select-styles;
}

.keywords-dropdown {
  @include base-ng-select-styles;
}

.upsell-email-automation.ng-select,
.upsell-email-automation.ng-dropdown-panel,
.cart-recovery-email-automation.ng-select,
.cart-recovery-email-automation.ng-dropdown-panel {
  @include base-ng-select-styles();
  &.ng-select-single .ng-select-container, &.ng-select-multiple .ng-select-container {
    height: 38px;
    border: 1px solid #ced4da;
  }
}

.ng-select.contact-list {
  .ng-input {
    // display: none;
  }
  .ng-value-container {
    max-height: 90px;
    overflow-y: auto;
  }

  &.ng-invalid.ng-touched .ng-select-container {
    border-color: var(--color-danger);
  }
  .ng-option.ng-option-disabled {
    color: var(--color-secondary);
  }
}

.h-46 {
  .ng-select-container {
    height: 46px !important;
  }
}

.contact-list-short {
  &.ng-dropdown-panel {
    .ng-dropdown-panel-items {
      max-height: 210px;
    }
  }
  &.ng-select {
    &.ng-invalid.ng-touched.ng-dirty .ng-select-container {
      border-color: var(--color-danger);
    }
    &.ng-invalid.ng-touched:not(.ng-dirty) .ng-select-container {
      border-color: #ced4da;
    }
  }
}

// styles for plugin selector
.plugin-select {
  .ng-select-container {
    justify-content: space-between;
  }
  .ng-clear-wrapper {
    text-align: center;
  }
  .ng-value-container {
    width: 90%;
    overflow: hidden;

    .ng-value {
      max-width: 100%;
      display: flex;

      .ng-value-label {
        max-width: calc(100% - 12px);
        text-overflow: ellipsis;
        overflow: hidden;
      }

    }
  }

}
// this placed here to resolve bug PF-1700 where cursor is overlaying first letter
.ng-select-container {
  .ng-value-container {
    .ng-value {
      padding: 0 3px;
    }
  }
}

// for cases when dialog window have ng-select and higher z-index, dropdown will not be visible
.Countries-Select {
  &.ng-dropdown-panel {
    z-index: 2001 !important;
    margin-left: 3px;
  }
}

// styles for country code dropdown for phone input component
ng-select.CountryCode {
  .ng-select-container {
    height: 40px !important;
    border-right: none !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    .ng-input {
      opacity: 0 !important;
      caret-color: transparent !important;
    }
  }

  &.ng-select-focused {
    .ng-select-container {
      border-color: #bebebe !important;
      box-shadow: none !important;
    }
  }

  &.ng-select-filtered {
    .ng-value {
      visibility: visible !important;
    }
  }
}

ng-select._upsell-preview {
  .ng-select-container {
    height: 48px !important;
  }
  .ng-value-container {
    padding-top: 10px;
    padding-top: 15px;
    opacity: 0.7;
  }
}

ng-dropdown-panel.CountryCode {
  width: fit-content !important;
  z-index: 2001 !important;

  .ng-dropdown-panel-items {
    width: fit-content;
    max-width: 220px;
    @include pf.custom-scrollbar();

    .ng-option {
      font-size: 13px;
      padding: 4px;
      border-top: 1px solid #e6e6e6;
    }
  }
}

.state-toggle.ng-select {
  .ng-option.ng-option-selected {
    background: var(--color-primary-new!important);
    color: #fff!important;
  }
  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    box-shadow: none!important;
    border-color: #ccc!important;

  }
}
