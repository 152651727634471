@use 'proof-factor/import' as pf;

.L-Settings {
  &-Head {
    justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 350px;
    grid-gap: 26px;

    @include pf.breakpoint-768 {
      grid-template-columns: 1fr 40px !important;
    }
  }

  &-Content {
    display: grid;
    grid-template-columns: 1fr 350px;
    grid-gap: 26px;
    margin-top: 12px;

    &-Main {}

    &-Aside {}
  }
}
