@use 'proof-factor/import' as pf;
// nebular styles, to be refactored
.btn {
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  font-family: 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  border: 2px solid transparent;
  transition: none;
  cursor: default;
  padding: 0.75rem 1.5rem;
  user-select: none;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0.375rem;
  outline: none !important;

  @include pf.breakpoint-380 {
    padding: 0.5rem 1rem;
    text-transform: capitalize;
  }

  @include pf.breakpoint-320 {
    font-size: 15px;
    padding: 0.5rem 0.75rem;
    margin: 5px !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:hover {
    color: #fff;
    cursor: default;
  }

  &:active {
    color: #2a2a2a;
    background-color: #bbc0c6;
    border-color: transparent;
    box-shadow: none !important;
  }

  &.btn-sm {
    padding: 0.625rem 1.5rem;
    font-size: 0.875rem;
    line-height: 1.25;
    border-radius: 0.375rem;
  }

  &.btn-outline-secondary {
    border: 2px solid #dadfe6;
    color: #2a2a2a;
    background-color: transparent;

    &:hover {
      background-color: #dfe3ea;
      border-color: transparent;
    }
  }

  &.btn-outline-primary {
    border: 2px solid #0095f7;
    color: #2a2a2a;
    background-color: transparent;

    &:hover {
      color: #fff;
      background-color: #24a4f8;
      border-color: transparent;
    }

    &:active {
      color: #fff;
      background-color: #0080d4;
      border-color: transparent;
      box-shadow: none;
    }
  }
}
