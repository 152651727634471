.LanguageChangelist {
  & {
    margin: 0;
    padding: 0 !important;
    list-style: none;
  }

  &-Item {
    margin: 10px 0;
    font-size: 14px;
    line-height: 18px;
    color: #555555;
    display: flex;
  }

  &-Before {
    width: 50%;
    text-align: right;
    padding-right: 15px;
  }

  &-After {
    width: 50%;
    text-align: left;
    padding-left: 15px;
    position: relative;
    color: var(--color-success);

    &::before {
      content: '';
      position: absolute;
      top: (20px - 6 * 2) *0.5;
      left: (-7px * 0.5);
      height: 0;
      width: 0;
      border: 6px solid transparent;
      border-left-color: #d3d6db;
      border-left-width: 7px;
      border-right-width: 0;
    }
  }
}
