@use 'proof-factor/import' as pf;

.CampaignSection {
  &-Header {
    min-height: 52px;
    border-top: 1px solid var(--separator);
    border-bottom: 1px solid var(--separator);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: column;
    text-align: center;
    padding: 15px 20px;
    @include pf.breakpoint-sm {
      padding: 10px 20px;
      text-align: left;
      flex-flow: row;
    }
  }
  &-Body {
    padding: 20px;
  }

  & &-Title {
    color: #555;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    margin: 0;
  }

  &-Subtitle {
    color: var(--color-secondary-alt);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin: 5px 0 0;
    @include pf.breakpoint-sm {
      margin: 0 auto 0 10px;
    }
  }

  &-Button {
    margin: 10px 0 0;
    @include pf.breakpoint-sm {
      margin: 0;
    }
  }
}
