@use 'proof-factor/import' as pf;
/**
 * Main header stuff
 */
 one-header._black-bg {
  .one-header {
    color: #fff;
    background: #000;
  }
 }

.one-header {
  display: grid;
  // grid-template-columns: 1fr min-content min-content;
  grid-template-columns: min-content min-content min-content;
  height: var(--header-height);
  width: 100%;
  @include pf.card();
  border-radius: 30px;
  // padding: 0 12px;

  &-cell {
    align-self: center;
    justify-self: stretch;
    height: var(--header-height);
    display: flex;
    align-items: center;

    &::before,
    &::after {
      display: none;
    }

    &._left {
    }
    &._mid {
      height: 100%;
    }
    &._right {
    }
  }

  /**
   * Main menu stuff
   */
  .one-main-nav {
    display: flex;
    height: var(--header-height);

    // dropdown icons
    one-icon[name=caret-forward],
    one-icon[name=caret-down] {
      font-size: 8px;
      margin-left: 5px;
    }
    one-icon[name=caret-down] {
      margin-top: 3px;
    }

    &-item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      transition: all 180ms;
      user-select: none;
      color: #000;
      text-decoration: none !important;

      &._highlighted {
        color: var(--color-one-purple);
      }

      // --> Dropdown logic
      // &[tabindex]:focus {
      &:hover {
        & > .one-main-nav-dropdown {
          visibility: visible;
          pointer-events: auto;
          transform: scale(1);
          opacity: 1;

          &._is-user {
            right: 0;
          }
        }
      }
      .one-main-nav-dropdown > .one-main-nav-dropdown-item:hover {
        & > .one-main-nav-dropdown {
          visibility: visible;
          pointer-events: auto;
          transform: scale(1);
          opacity: 1;
        }
      }
      // <-- Dropdown logic

      &._is-stats,
      &._is-user {
        padding: 0;
      }

      &._is-stats {
        flex: 1 1 auto;
      }

      &._active {
        color: var(--color-primary);
        text-shadow: 0 0 1px var(--color-primary);
        box-shadow: inset 0 3px 0 var(--color-primary);
      }

      &:hover:not(._cursor-default) {
        color: var(--color-primary);
      }

      a {
        text-decoration: none !important;
      }

      &._border-left {
        border-left: 1px solid var(--separator);
      }

      &._border-right {
        border-right: 1px solid var(--separator);
      }

      &._right-align {
        margin-left: auto;
        margin-right: 0;
      }
    }

    // Shrink menu for small desktops
    @include pf.between-1024-1240 {
      // :not(._is-embedded) & {
      //   .Header-Item._is-user {
      //     margin-left: auto;
      //     margin-right: 0;
      //   }
      // }
      .one-main-nav-item {
        font-size: 13px;
        padding: 5px;
        &._is-stats {
          padding: 0;
        }
        // padding: 10px 7px;
      }
      .one-main-nav-item._is-logo {
        // margin-right: 7px;
      }
      .one-main-nav-item._is-fs-desktop {
        // margin-left: auto;
        // margin-right: 0;
      }
    }
  }

  .one-main-nav-dropdown {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: -1px;
    border-radius: 4px;
    transform-origin: left top;
    outline: 0;
    visibility: hidden;
    max-height: calc(100vh - var(--header-height));
    overflow-y: auto;
    overflow-x: hidden;
    // transform: scale(0.95);
    opacity: 0;
    background: #fff;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12);
    z-index: 1;

    // Nested dropdown
    .one-main-nav-dropdown {
      top: 0;
      left: 100%;
    }

    // Special position for user dropdown
    &._is-user {
      left: auto;
      right: 0;
      transform-origin: right top;
      padding: 10px 0;

      @include pf.breakpoint-660 {
        position: fixed;
        top: var(--header-height);
        height: calc(100vh - var(--header-height));
        border-radius: 0;
        z-index: -1;
        right: -100%;
        transition: all 0.3s;
      }
    }

    &-item {
      display: block;
      position: relative;
      max-width: 100%;
      padding: 0 16px;
      line-height: 48px;
      color: #000;
      height: 48px;
      white-space: nowrap;
      text-align: left;
      text-decoration: none !important;
      text-shadow: none;
      user-select: none;
      cursor: pointer;

      &._active {
        color: pf.$color-primary;
        text-shadow: 0 0 1px pf.$color-primary;
        box-shadow: inset 3px 0 0 pf.$color-primary;
      }

      &:hover,
      &:active {
        background: rgba(0, 0, 0, 0.04);
      }
    }
  }

  /**
   * Mobile menu stuff
   */
  &-logo-mobile {
    display: none !important;
  }
  ._nav-visible-mobile {
    display: none;
  }
  .one-user-nav._mobile {
    display: none;
  }
  &-menu-btn {
    display: none;
  }
  @include pf.breakpoint-660 {
    grid-template-columns: 1fr min-content min-content;
    border-radius: 0;

    &-logo-mobile {
      display: grid !important;
    }
    .one-user-nav._mobile {
      display: flex;
    }

    &-menu-btn {
      font-size: 28px;
      display: grid;
      justify-content: center;
      align-items: center;
      height: var(--header-height);
      width: var(--header-height);
      cursor: pointer;
      one-icon {
        padding: 0;
      }
    }

    .one-main-nav-item._is-user {
      // A hack to make the user dropdown toggleable
      &:focus {
        pointer-events: none;
        .one-user-nav-backdrop {
          width: 100vw;
          height: 100vh;
          opacity: 1;
        }
      }
    }
    .one-main-nav-item a {
      border-radius: 0;
    }
    ._nav-visible-mobile {
      display: block;
    }
    ._nav-hidden-mobile {
      display: none !important;
    }

    one-main-nav-stats {
      display: none;
    }
  }
}
