$color-primary: #0095f7;
$color-green: #20b57a;
$color-success: #50d73f;
$color-success-alt: #29cd8c;
$color-danger: #ff4c6a;
$color-warning: #ffa100;
$color-info: #17a2b8;
$color-secondary: #a5abb3;

$color-primary-new: #14a5ff;
$color-secondary-alt: #798c99;
$color-secondary-alt-2: #dfe3ea;

$color-shopify-indigo: #5c6ac4;
$color-shopify-purple: #8463fd;
$color-one-purple: #7f5cff;
$color-shopify-blue: #006fbb;
$color-shopify-green: #298160;
$color-shopify-darkgreen: #020303;
$color-shopify-red: #bf0711;

$separator: #ebeef2;
$box-shadow-grey: #dfe3eb;
$box-shadow-lightgrey: #0f263414;
$dark-separator: #dbe0e7;

$header-background: #005c97;
$bar-separator-color: $separator;
$preview-container-background: #dadee3;

// From nebular
$linkColor: #005c97;
$formControlFocusBorderColor: $color-primary-new;
$formControlFocusBg: #fff;
$formControlTextColorPrimary: #2a2a2a;
$formControlTextColorSecondary: #a4abb3;

$table-head-color: #f5f5f5;

$common-text-primary: #2a2a2a;
$common-text-secondary: #555;

$white: #fff;
$black: #000;
$grey: #c4c4c4;

$control-subtitle-background: #fff;
$control-subtitle-text: #333;
$control-subtitle-invert-background: #fff;
$control-subtitle-invert-text: #0095f7;

$switch-device-button-background: #7e7e7f;
$switch-state-button-background: #7e7e7f;

$tooltip-background: #828587;

$eye-icon-hover-background: #a5abb3;
$eye-icon-hover-text: #fff;
$eye-icon-active-background: #6c757d;
$eye-icon-active-text: #fff;

$app-margin-x: 20px;
$header-height: 60px;
$header-height-compact: 44px;
