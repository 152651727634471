@use 'sass:color';
@use 'proof-factor/import' as pf;
.ProductsList {
  position: relative;
  height: 300px;
  overflow-y: auto;
  border-radius: 6px;
  border: 1px solid pf.$color-secondary-alt-2;
  @include pf.custom-scrollbar();

  &.h-200 {
    max-height: 200px;
  }

  &.h-150 {
    max-height: 150px;
  }
  &.h-182 {
    max-height: 182px;
  }
  &.h-120 {
    max-height: 120px;
  }
  &.h-single {
    max-height: 122px;
  }

  &.has-error {
    border-color: pf.$color-danger;
    color: pf.$color-danger;
  }

  &-Search {
    position: relative;
    input {
      padding-right: 70px;
    }
    button {
      position: absolute;
      top: 3px;
      right: 3px;
    }
  }

  &-Head {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
  }

  &-Item {
    cursor: pointer;
    &Row {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      height: 60px;
      padding: 5px 10px;
      overflow: hidden;

      &._no-selection {
        justify-content: center;
        height: 100%;
      }
    }

    &Title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &Clearable {
      width: 100%;
      display: flex !important;
      justify-content: space-between;
    }

    &Image {
      height: 50px;
      width: 50px;
      border-radius: 3px;
      border: 1px solid pf.$color-secondary-alt-2;
      margin-left: 10px;
      margin-right: 10px;

      &._no-image {
        height: 48px;
        width: 48px;
        background-image: url('/app_assets/shopify-logo-no-text.jpg');
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 90%;
        img {
          display: none;
        }
      }

      img {
        width: 48px;
        height: 48px;
        object-fit: contain;
      }
    }

    & + & {
      border-top: 1px solid pf.$color-secondary-alt-2;
    }
  }
}

.ProductVariants {
  &-Item {
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 60px;
    overflow: hidden;
    border-top: 1px solid pf.$color-secondary-alt-2;
    padding: 5px 10px 5px 25px;

    &._no-checkbox {
      cursor: default;
      .checkbox {
        pointer-events: none;
        span::before {
          display: none;
        }
      }
    }

    &Image {
      height: 50px;
      width: 50px;
      border-radius: 3px;
      border: 1px solid pf.$color-secondary-alt-2;
      margin-left: 10px;
      margin-right: 10px;

      &._no-image {
        height: 48px;
        width: 48px;
        background-image: url('/app_assets/shopify-logo-no-text.jpg');
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 90%;
        img {
          display: none;
        }
      }

      img {
        width: 48px;
        height: 48px;
        object-fit: contain;
      }
    }
  }
}

._remove-button {
  cursor: pointer;
  margin-left: 10px;
  opacity: 0.8;
  font-weight: 600;

  &:hover {
    color: pf.$color-danger;
    opacity: 1;
  }
}
