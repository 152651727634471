@use 'proof-factor/import' as pf;

.Page {
  background: #ffffff;
  color: #4b4b4b;
  border-radius: 5px;
  box-shadow: 0 2px 12px 0 #dfe3eb;
  border: 0 solid #ffffff;
  padding: 20px;
  position: relative;
  margin-bottom: 80px;

  .Title {
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 30px;

    &._h2 {
      color: #798c99;
      font-size: 28px;
    }

    //&._h3 {
    //  color: #98b0c0;
    //  font-size: 20px;
    //}
  }

  .Subtitle {
    font-size: 18px;
    font-weight: 400;
    color: #909ea7;
    margin-bottom: 30px;

    &._h2 {
      font-size: 16px;
    }
  }

  .Title + .Subtitle {
    margin-top: -20px;
  }
}

.InstructionSteps {
  margin: 0 0 40px;

  li {
    color: #909ea7;
    margin: 10px 0;
  }
}
.InstructionStep {
  &__Text {
    margin-bottom: 20px;
  }
  &__Image {
    margin: 0;
  }
  &__Double {
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 1fr;
    @include pf.breakpoint-1024 {
      grid-template-columns: 2fr 1fr;
    }
    @include pf.breakpoint-730 {
      grid-template-columns: minmax(0, 1fr);
    }
  }
}

.Screenshot {
  display: block;
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
  margin: 0 auto;
}
