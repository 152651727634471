@use 'proof-factor/import' as pf;

.Tip {
  margin-bottom: 20px;
  display: flex;
  border: 2px solid #abdbf4;
  background: #f5fbfe;
  font-size: 16px;
  line-height: 20px;
  border-radius: 5px;

  &__Title {
    background: #abdbf4;
    color: #fff;
    padding: 10px 20px;
    font-weight: 700;
    @include pf.breakpoint-500 {
      padding: 10px;
      font-size: 14px;
    }
  }

  &__Body {
    padding: 10px;
    font-weight: 300;
    color: #6b8490;
    width: 100%;
    @include pf.breakpoint-500 {
      font-size: 14px;
    }
  }

  & + & {
    margin-top: -10px;
  }

  &._clickable {
    cursor: pointer;
  }
}

.Tip._danger {
  border: 2px solid #ffa100;
  background: #fef9f5;
  .Tip__Title {
    color: #fff;
    background: #ffa100;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .Tip__Body {
    font-weight: normal;
    color: #585858;
  }
}

.Tip._success {
  border: 2px solid #4caf50;
  background: #fef9f5;
  .Tip__Title {
    color: #fff;
    background: #4caf50;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .Tip__Body {
    font-weight: normal;
    color: #585858;
  }
}

.Tip._shopify-green {
  border: 2px solid #298160;
  background: #fff;
  .Tip__Title {
    color: #fff;
    background: #298160;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .Tip__Body {
    font-weight: 500;
    color: #298160;
  }
}
