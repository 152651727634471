@use 'proof-factor/import' as pf;
.Config {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;

  &-Preview {
    flex: 1 0 60%;
    display: grid;
    grid-template-rows: 60px 1fr 50px;
    overflow: hidden;

    @include pf.breakpoint-992 {
      padding: 15px;
      grid-template-rows: 60px 350px 50px;
    }

    @include pf.breakpoint-600 {
      grid-template-rows: 60px 250px 50px;
    }

    .newsletter-preview-container,
    .info-popup-preview-container,
    .coupon-box-preview-container {
      position: relative;
      display: flex;
      justify-content: center;

      iframe {
        position: absolute;
        margin-top: 40px;
        transform-origin: 50% 0;
        border-radius: 5px;
      }
    }
  }

  &-Controls {
    flex-shrink: 0;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    padding-left: 10px;
    padding-right: 5px;
    @include pf.custom-scrollbar();

    @include pf.breakpoint-1024 {
      // max-height: none;
      height: auto;
      width: 100%;
      padding: 0 15px;
    }
    @include pf.breakpoint-600 {
      padding: 0;
    }
  }


  &._triggers-preview {
    //height: calc(100% - 10px);
    height: 100%;

    @include pf.breakpoint-1024 {
      flex-flow: row wrap;
      max-height: none;

      .Config-Preview {
        padding: 15px;
        grid-template-rows: 60px 400px;
      }
      .Config-Controls {
        height: auto;
      }
    }
    @include pf.breakpoint-600 {
      .Config-Preview {
        padding: 0;
      }
    }
  }

  @include pf.breakpoint-600 {
    min-height: auto;
  }

}

// Raw component
.ConfigCard {
  border-radius: 1px;
  box-shadow: 0 2px 12px 0 #dfe3eb;
  margin-bottom: 15px;

  &-Head {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding: 10px;
    color: #fff;
    background: pf.$color-primary;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  &-Body {
    font-size: 14px;
    background: #fff;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  &-BodyLine {
    padding: 10px 1rem;
    border-top: 1px solid #f5f5f5;
  }

  &-Subtitle {
    padding: 10px;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    background: #ebf7ff;
  }
}

// Modifications


.ConfigTabSwitch {
  display: flex;
  flex-flow: row nowrap;
  border-radius: 6px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  &._advanced-tabs {
    margin: 0 -20px -2px;
  }

  &-Button {
    flex: 1 1 50%;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #a5abb3;
    user-select: none;
    background: #fff;
    cursor: pointer;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    &:first-child {
      box-shadow: inset -1px 0 3px 0 rgb(0 0 0 / 15%);
    }
    &:last-child {
      box-shadow: inset 1px 0 3px 0 rgb(0 0 0 / 15%);
    }

    &:not(._active) {
      border-bottom: 1px solid rgba(pf.$color-primary, 0.15);
      box-shadow: inset 0 0 3px 0 rgb(0 0 0 / 15%);
      &:hover {
        background: rgba(pf.$color-primary, 0.06);
        color: pf.$color-primary;
      }
    }

    &:not(:first-child):not(:last-child) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &._active {
      color: pf.$color-primary;
      border-top: 3px solid pf.$color-primary;
      box-shadow: none;
    }

    & + & {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }
  }
}

.ConfigControl {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &._grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 15px;
  }

  &._disabled {
    & > * {
      pointer-events: none !important;
      opacity: 0.7;
    }
  }

  &-Label {
    flex: 1 1 35%;
    padding-right: 5px;
  }

  &-Input {
    flex: 1 1 70%;
  }
}
