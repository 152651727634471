@use 'proof-factor/import' as pf;

$transition: all 180ms;

@mixin gridify() {
  &._for-grid {
    display: grid;
    padding: 0;

    &._2col {
      grid-template-columns: 40% 60%;
    }

    & > * {
      padding: 10px 16px;

      &:not(:first-child) {
        border-left: 1px solid #e6edf3;
      }
    }
  }
}

.PluginConfig {
  height: calc(100% - 10px);
  display: flex;
  // margin: 0 -20px;
  flex-flow: row nowrap;
  border-radius: 6px;
  background: #f6f6f7;

  &-Sticky-Header {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 10;
    .PluginConfig-Title {
      background-color: #f3f8fc;
    }
    .PluginConfig-Tabs {
      background-color: #fff;
    }
  }

  &-Control-Label {
    &._one-line {
      min-width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &-Control-Input {
    &._full-width {
      width: 100% !important;
    }
    &._unset-width {
      width: unset !important;
    }
    &._black {
      color: black;
    }
    &._search {
      padding-right: 40px;
    }
  }

  .newsletter-preview-container,
  .info-popup-preview-container,
  .coupon-box-preview-container {
    display: flex;
    justify-content: center;
    //align-items: center;
    position: relative;
    background: #f6f6f7;

    iframe {
      position: absolute;
      margin-top: 40px;
    }

  }

  &._has-preview {
    @include pf.breakpoint-1024 {
      max-height: none;
    }
    @include pf.breakpoint-600 {
      margin-bottom: 15px;
    }
  }

  &._has-split-controls,
  &._has-preview {
    // margin: 0 -20px -30px;
    @include pf.breakpoint-1024 {
      flex-flow: row wrap;
    }

    & + .PluginConfig {
      margin-top: 20px;
    }
  }

  &._column {
    @include pf.breakpoint-1024 {
      flex-flow: column;
      .PluginConfig-Preview {
        flex: 1 0 420px;
      }
    }

    & + .PluginConfig {
      margin-top: 20px;
    }
  }

  &._has-split-view {
    margin: 0 -20px -30px;
    @include pf.breakpoint-1024 {
      flex-flow: row wrap;
    }

    & + .PluginConfig {
      margin-top: 20px;
    }
  }

  &._has-unlayer {
    margin: 0 -20px -20px;
  }

  &._one-col {
    display: flex;
    flex-flow: column nowrap;
    margin: 0 -20px;
    border-radius: 6px;
  }

  &-ViewSwitch {
    position: absolute;
    left: 50%;
    top: 5px;
    transform: translateX(-50%);
    width: 100px;
    height: 32px;
    border: 1px solid #727272;
    border-radius: 6px;
    &-Item {
      display: inline-block;
      width: 50%;
      height: 100%;
      font-size: 23px;
      line-height: 29px;
      text-align: center;
      cursor: pointer;
      color: #a4abb3;
      background-color: rgba(0, 0, 0, 0);
      transition: $transition;
      border-radius: 6px 0 0 6px;
      &._active,
      &:hover {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.3);
      }

      &:last-child {
        border-radius: 0 6px 6px 0;
        border-left: 1px solid #727272;
      }
    }
  }

  &._mobile-device {
    max-height: 835px;
    @include pf.breakpoint-576 {
      max-height: none;
    }

    .PluginConfig-Preview {
      min-height: 835px;
    }
  }

}

.PluginConfig-Select {
  position: relative;

  @include pf.breakpoint-600 {
    display: block !important;
  }

  &._with_label {
    width: 65%;
  }

  select {
    height: 41px;
    width: 100%;
    border: 1px solid #bdc0c1;
    border-radius: 4px;
    padding: 0 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") no-repeat;
    background-position-x: 99%;
    background-position-y: 7px;

    &:focus {
      border: 1px solid #bdc0c1;
    }

  }

  &-Title {
    position: absolute;
    background: #fff;
    font-family: 'Roboto', 'Helvetica Neue', sans-serif;
    color: #000;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    top: -9px;
    left: 5px;
    padding: 0 5px;
  }
}

.PluginConfig-Tabs {
  display: grid;
  align-items: center;
  justify-items: stretch;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  margin: 0 -20px -2px;
}

.PluginConfig-Tab {
  padding: 13px 10px 12px;
  text-align: center;
  color: pf.$color-secondary-alt;
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-top-color: #dadfe6;
  border-bottom-color: pf.$color-primary-new;
  box-shadow: inset 2px -2px 3px rgba(0, 0, 0, 0.3);
  transition: all 180ms;

  &:not(._active):hover {
    color: pf.$color-primary-new;

    .PluginConfig-TabNumber {
      transform: scale(1.1);
      background: pf.$color-primary-new;
    }
  }

  @include pf.breakpoint-400 {
    white-space: unset;
    padding: 13px 5px 12px;
    font-size: 14px;
    align-self: stretch;
  }

  &._active {
    color: pf.$color-primary-new;
    border-left: none;
    border-right: 2px solid;
    border-color: pf.$color-primary-new pf.$color-primary-new transparent;
    box-shadow: inset 2px -2px 3px rgba(0, 0, 0, 0);

    &:last-child._active {
      border-left: 2px solid;
      border-right: none;
    }

    .PluginConfig-TabNumber {
      background: pf.$color-primary-new;
    }
  }


  &Number {
    color: #fff;
    width: 22px;
    height: 22px;
    font-size: 14px;
    line-height: 12px;
    display: inline-flex;
    font-weight: normal;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 5px;
    transform: scale(1);
    background: pf.$color-secondary-alt;
    transition: all 180ms;
  }
}

.newsletter-preview-container,
.fortune-wheel-preview-container,
.info-popup-preview-container,
.coupon-box-preview-container {
  height: 100%;

  @include pf.breakpoint-768 {
    min-height: 400px;
  }
}

.PluginConfig-Controls {
  width: 100%;
  overflow: auto;
  padding: 0 20px;
  @include pf.custom-scrollbar;
  // overflow-x: hidden;

  @include pf.breakpoint-768 {
    min-height: 600px;
  }

  @include pf.between-992-1280 {
    flex: 1 1 342px;
    overflow-x: visible;
    z-index: 1;
    opacity: 1;
  }

  ._has-split-controls & {
    width: 50%;

    @include pf.breakpoint-768 {
      width: 100%;
    }
  }

  ._reduced-controls-width & {
    width: 30% !important;

    @include pf.breakpoint-1200 {
      width: 50% !important;
    }
  }

  ._has-split-view & {
    width: 50% !important;
    min-height: 660px;

    @include pf.breakpoint-1024 {
      width: 100% !important;
      max-height: none;
      min-height: auto;
    }
  }

  ._half & {
    width: 500px;
    @include pf.breakpoint-992 {
      width: 100%;
    }
  }

  ._has-preview & {
    min-width: 430px;
    width: 100%;
    &._wide {
      // width: 50%;
      flex: 1 0 40%;
      @include pf.breakpoint-1280 {
        width: 100%;
      }
    }

    @include pf.between-992-1280 {
      width: 100%;
    }

    @include pf.breakpoint-1024 {
      padding-left: 0;
    }

    @include pf.breakpoint-992 {
      width: 100%;
      max-height: none;
      min-height: auto;
    }
    @include pf.breakpoint-600 {
      min-width: auto;
    }
  }

  &._flex-column {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &._bdrr {
    border-right: 1px solid #f5f5f5;
  }

  &._bdrl {
    border-left: 1px solid #f5f5f5;
  }

  ._max-height-none & {
    max-height: none;
  }
}

.PluginConfig-Live-Preview {
  .Button {
    white-space: nowrap;
  }
  .button_container {
    text-align: center;
    margin: 0 auto;
  }
}

.PluginConfig-Live-Preview-Company-Off {
  font-size: 12px;
  font-weight: bold;
  border-radius: 10px;
  background: pf.$color-danger;
  color: #ffffff;
  padding: 1px 8px;
  text-transform: uppercase;
}

.PluginConfig-InfoLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  @include pf.breakpoint-500 {
    flex-direction: column;
    margin-bottom: 0.75rem;
    p, button {
      margin: 2px;
    }
  }

  &-Items {
    min-width: 45%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.PluginConfig-InfoLine-Title {
  flex: 1 1 auto;
}

.PluginConfig-InfoLine-Text {
  margin-bottom: 0;
  padding: 0 10px;
  @include pf.breakpoint-500 {
    font-size: 14px;
  }
}

.PluginConfig-Preview {
  pointer-events: none;
  user-select: none;
  overflow: hidden;
  flex: 1 0 60%;
  background: #f6f6f7;

  .PreviewSizeToggle {
    z-index: 3;
  }

  @include pf.between-992-1280 {
    // flex: 1 0 420px;
  }

  @include pf.breakpoint-1024 {
    min-height: 750px;
    flex: 1 1 100%;

    iframe {
      transform: unset;
      position: static;
      left: unset;
      top: unset;
    }
  }

  @include pf.breakpoint-600 {
    min-height: 750px;
  }

  &._position-relative {
    position: relative;
  }

  &._switch-padding {
    padding-top: 44px;
  }

  &._has-pointer-events {
    pointer-events: auto;
  }

  &._stretch-content {
    flex: 1 0 60%;
    display: grid;
    grid-template-rows: 60px 1fr 50px;
    @include pf.breakpoint-400 {
      grid-template-rows: 60px 1fr auto;
    }
  }

  ._has-split-view & {
    width: 50% !important;
    flex: 1 1 50%;

    @include pf.breakpoint-1024 {
      flex: 1 1 100%;
      width: 100% !important;
    }
  }

  ._increased-preview-width & {
    min-width: 60% !important;
    flex: 1 1 60%;

    @include pf.breakpoint-1200 {
      min-width: 50% !important;
      flex: 1 1 50%;
    }

    @include pf.breakpoint-1024 {
      min-height: 660px;
    }
  }
  & .preview-toggle-container {
    margin: 0 auto;
    z-index: 1;
  }
}

.PluginConfig-Title {
  background: #e6f0f97a;
  border-bottom: 1px solid #e6edf3;
  // border-top: 1px solid #e6edf3;
  font-weight: 500;
  color: #405d75;
  margin: 0 -20px;
  padding: 10px 16px;

  @include gridify;

  &._flex {
    display: flex;
    align-items: center;
  }
}

.PluginConfig-Subtitle {
  position: relative;
  background: #fafdff;
  border-bottom: 1px solid #eef5fb;
  border-top: 1px solid #e6edf3;
  color: #67839b;
  margin: 0 -20px;
  padding: 10px 16px;
  transition: all 0.15s;

  ._togglable {
    position: absolute;
    right: 20px;
    height: 21px;
  }

  &._hoverable:hover {
    ._togglable {
      transform: rotate(180deg);
    }
  }

  &._with-icon {
    position: relative;
    pf-visibility-control {
      position: absolute;
      right: 15px;
      top: 6px;
    }
  }

  @include pf.breakpoint-1280 {
    font-size: 16px;
    padding: 6px 20px;

    ._togglable {
      height: 18px;
    }
  }
}

.PluginConfig-Line {
  margin: 0 -20px;
  padding: 10px 16px;
  border-top: 1px solid #f5f5f5;

  &._local-sms-container {
    padding-right: 0;
    padding-left: 0;
    margin: 0 -4px;
  }

  @include gridify;

  &._disabled {
    & > * {
      pointer-events: none;
      opacity: 0.7;
    }
  }
  &._double {
    padding: 10px;
  }
}

.PluginConfig-Unlayer {
  border-top: 2px solid #f5f5f5;
  margin: 0 -20px;
  display: flex;
  flex: 1;
}

.PluginConfig-Control {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  &._p-relative {
    position: relative;
  }


  &._title {
    color: #262626;
    font-weight: 600;
    &.light-blue {
      color: #0095f7;
      font-weight: 400;
    }
    ion-icon[name='chevron-down'] {
      margin: auto 10px;
      font-size: 20px;
      color: #262626;
      transform: rotate(-90deg);
      transition: transform 0.3s;
    }
    &.opened {
      color: #14a5ff;
      ion-icon[name='chevron-down'] {
        color: #14a5ff;
        transform: rotate(0deg);
      }
    }
  }

  &._title,
  &._double,
  &._one-line {
    flex-flow: row nowrap;
    padding-top: 15px;

    @include pf.between-1024-1280 {
      flex-flow: row wrap;

      .PluginConfig-Control-Input {
        padding: 5px 0;
      }
    }

    @include pf.breakpoint-600 {
      flex-flow: row wrap;

      .PluginConfig-Control-Input {
        padding: 5px 0;
      }
    }
  }
  &._unset-input-width {
    .PluginConfig-Control-Input {
      width: unset;
    }
  }

  &._with-button {
    display: flex;
    flex-flow: row nowrap;

    @include pf.breakpoint-500 {
      flex-flow: column wrap;
      input, button {
        margin: 5px;
      }
    }

    button {
      height: 41px;
      margin-left: 10px;
    }
  }

  &._flex-start {
    align-items: flex-start;
  }

  &-Error {
    position: absolute;
    left: 10px;
    top: -9px;
    font-size: 12px;
    background: #fff;
    padding: 0 10px;
  }

  &-Label {
    max-width: 50%;
    min-height: 41px;
    font-size: 14px;
    padding: 10px 0;
    margin-bottom: 0;
    color: #333;

    @include pf.breakpoint-600 {
      flex: 1 1 100%;
      max-width: none;
      min-height: unset;
      padding-top: 0;
      display: block !important;
    }

    &._full-width {
      max-width: 100%;
    }

    &._no-spacing {
      padding: 0;
      min-height: auto;
      line-height: unset;
    }

    &._bold {
      font-weight: 600;
    }

    &._black {
      color: #333;
    }

    &._with-switch-fw {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      line-height: unset;

      &._jcfs {
        justify-content: flex-start;
      }
      @include pf.breakpoint-600 {
        flex-flow: row wrap;

        pf-switch-box {
          flex: 1 1 100%;
          margin-top: 10px;
        }
      }
    }
  }

  &-Input {
    width: 100%;
    max-width: 100%;

    ._one-line & {
      width: 50%;
      max-width: 50%;

      @include pf.breakpoint-600 {
        flex: 1 1 100%;
        max-width: none;
      }

      &._full-width {
        max-width: 100%;
      }
    }

    &._invalid {
      border-color: #f10000 !important;
    }

    ._double & {
      padding: 5px;

      & + .PluginConfig-Control-Input {
        padding: 5px;

        @include pf.between-992-1280 {
          padding: 5px 0;
        }
        @include pf.breakpoint-600 {
          padding: 5px 0;
        }
      }

      @include pf.breakpoint-600 {
        flex: 1 1 100%;
        max-width: none;
        padding: 5px 0;
      }
    }

    mat-select {
      padding: 8px !important;
    }

    &.Range {

      .Range-Value {
        flex: 0 0 auto;
        margin-right: 10px;
      }

      .Range-Input {
        flex: 1 1 auto;
        width: 100%;
      }
    }
  }

  &-Textarea {
    width: 100%;
    line-height: 1.4;

    ._one-line & {
      width: 50%;
      max-width: 50%;
      @include pf.breakpoint-600 {
        flex: 1 1 100%;
        max-width: none;
      }
    }

    &::-webkit-scrollbar {
      width: 14px !important;
      height: 14px !important;
      background-color: #f00;
    }

    &::placeholder {
      font-size: 14px;
    }
  }

  &-Input2,
  &-Select {
    display: block;
    width: 100%;
    max-width: 160px;
    color: #2a2a2a;
    padding: 8px;
    font-size: 1rem;
    height: 41px;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    border: 2px solid #dadfe6;
    font-family: 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    border-radius: 0.375rem;
    outline: none !important;

    &._fw {
      max-width: none;
      width: 100%;
    }

    &._fwfx {
      // width: 100%;
      flex: 1 1;
      max-width: none;
    }

    &._extra-width {
      max-width: 195px;
    }

    &._sm {
      height: 33px;
      font-size: 14px;
      line-height: 1.8;
      padding: 2px 8px;
    }

    &[disabled] {
      opacity: 0.9;
      background-color: #e6e6e6;
    }
  }
}

.PluginConfig-Description {
  padding: 5px 20px 15px;
  margin: 0 -20px;
  border-top: 1px solid #f5f5f5;
  background: #fafdff;

  &-Title {
    padding: 10px 0;
  }

  &-Text {
    color: #555;
  }
}

.PluginConfig-Info {
  &:hover {
    cursor: pointer;
  }
}

.PluginConfig-DesignCard {
  margin-bottom: 15px;
  box-shadow: 4px 4px 8px rgb(0, 0, 0, 0.08);

  &:last-child {
    margin-bottom: 2px;
  }

  & .PluginConfig-Line {
    background: #fff;
    padding: 0 1rem 1rem;
    margin: 0;
  }

  & .PluginConfig-Subtitle {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    background-color: pf.$control-subtitle-background;
    color: pf.$control-subtitle-text;
    border-bottom: 1px solid #d6edfd;
    cursor: pointer;
    padding: 8px 10px;
    margin: 0;
    box-shadow: rgba(0, 0, 0, 0.08) 0 2px 8px;

    .sticker {
      display: none;
      background-color: pf.$control-subtitle-invert-text;
      width: 4px;
      height: 100%;
      margin: -10px;
      position: absolute;
    }

    // control subtitle arrow
    ion-icon[name='chevron-up'] {
      margin: auto 10px;
      transition: transform 0.3s;
      transform: rotate(90deg);
      font-size: 20px;
    }

    .slice-title {
      ion-icon[name='chevron-up'] {
        margin: auto 10px auto 24px;
      }
    }
  }

  & .PluginConfig-Subtitle ._local-togglable {
    margin-right: 10px;
    transform: rotate(-90deg);
    transition: transform 0.15s;

    &._toggled {
      transform: rotate(0deg);
    }
  }

  & ._invert {
    background-color: pf.$control-subtitle-invert-background;
    color: pf.$control-subtitle-invert-text;

    .sticker {
      display: block;
    }

    // control subtitle arrow
    ion-icon[name='chevron-up'] {
      transform: rotate(180deg);
    }

    & img {
      filter: brightness(0) saturate(100%) invert(100%)
      sepia(1%) saturate(2%) hue-rotate(174deg) brightness(119%) contrast(100%);
    }
  }

}

.NewConfig-Controls {
  width: 420px;
  background: #f6f6f7;
  padding-left: 10px;
  padding-right: 5px;
  // overflow-x: hidden;
}

.PluginConfig-Aside {
  display: grid;
  flex: 1 0 40%;
  grid-template-rows: 60px 1fr;
  @include pf.breakpoint-1024 {
    flex: 1 1 100%;
  }
  &._socialPlugin {
    margin-left: 15px;
    max-height: 100%;
    height: 100%;
    @include pf.breakpoint-576 {
      height: auto;
    }
  }
}

.PluginConfig-Radio {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  //margin: 10px 0 20px;


  &-Item {
    display: flex;
    align-items: baseline;
    position: relative;
    user-select: none;
    margin-right: 20px;


    input[type=radio] {
      position: absolute;
      opacity: 0;
    }

    .checkmark {
      position: absolute;
      top: -1px;
      left: 0;
      height: 24px;
      width: 24px;
      border: 2px solid #bdc0c1;
      border-radius: 50%;
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        display: none;
        top: 4px;
        left: 4px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: pf.$color-primary;
      }
    }

    & input:checked ~ label .checkmark {
      border: 2px solid pf.$color-primary;
    }
    & input:checked ~ label .checkmark::after {
      display: block;
    }

    label {
      cursor: pointer;
      display: flex;
      flex-flow: column nowrap;
      font-size: 14px;
      padding-left: 35px;
      color: #333333;
      background: #fff;
      transition: all 180ms;

    }

    small {
      margin-top: 5px;
      display: block;
    }

    & + & {
      margin-left: 10px;
    }
  }
}

.PluginConfig-BrandingSwitchCover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}

._grayBoxMsg {
  padding: 16px !important;
  background: #f9f9f9;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  color: #262626;
  width: 100%;
}

._grayBoxCheckedArea {
  width: 100%;
  background: #f7f7f7;
  border-radius: 4px;
  padding: 10px 12px 10px 35px !important;
}

.ai-label {
  z-index: 99;
  display: block;
  background-image: url('/app_assets/ai/ai-white.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  background-color: #0095f7;
  color: #fff;
  padding: 18px 12px;
  position: absolute;
  top: auto;
  height: 36px;
  width: 30px;
  // right: calc(100% - 2px);
  right: calc(100% - 1px);
  @include pf.breakpoint-1024 {
    width: 22px;
    background-size: 16px;
    padding: 18px 10px;
  }
  &.hover {
    background-image: none;
    background-color: #000;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
    text-align: center;
    font-size: 10px;
    line-height: 12px;
    @include pf.breakpoint-1024 {
      width: 22px;
    }
    &::before {
      content: 'ONE\a AI';
      white-space: pre-wrap;
    }
  }
  &.error {
    background-color: #f44336;
    background-image: none;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    text-align: center;
    font-size: 10px;
    line-height: 14px;
    @include pf.breakpoint-1024 {
      font-size: 8px;
    }
    &::before {
      content: 'Error';
    }
  }
  &.hidden {
    display: none;
  }
}


.PluginConfig._grid-layout,
.L-ThemeCarousel._grid-layout {
  display: grid;
  grid-template-columns: 60% 1fr;
  grid-template-rows: 60px 50px calc(100% - 100px);

  @include pf.breakpoint-1024 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 10px;
  }


  one-plugin-header-buttons {
    @include pf.breakpoint-1024 {
      grid-row: 4;
    }
  }

  pf-breadcrumbs {
    @include pf.breakpoint-1024 {
      grid-row: 1;
    }
  }

  .PluginConfig-Preview,
  .L-ThemeCarousel-Main {
    grid-column: 1;
    grid-template-rows: 1fr;
    @include pf.breakpoint-1024 {
      grid-row: 2;
    }
  }
  .L-ThemeCarousel-Main {
    @include pf.breakpoint-1024 {
      min-height: 500px;
    }
  }

  .CarouselView {
    grid-template-rows: 1fr;
  }

  .PluginConfig-Aside,
  .FortuneWheelSlices {
    grid-row: 3 / 4;
    grid-template-rows: 1fr;
    grid-column: 2;

    @include pf.breakpoint-1024 {
      grid-row: 5;
      grid-column: 1;
    }
  }

  one-preview-toggle {
    grid-row: 2;
    grid-column: 1 / 3;
    justify-content: center;
    @include pf.breakpoint-1024 {
      grid-column: 1;
      grid-row: 3;
    }
  }
}
