@use 'proof-factor/import' as pf;

.PluginItem {
  height: 100%;

  &-Box {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid pf.$separator;
    transition: 0.2s, padding-bottom 0s;
    text-align: center;
    position: relative;
    overflow: hidden;
    @include pf.breakpoint-lg {
      min-width: 260px;
    }

    &._active {
      box-shadow: 3px 3px 0 0 rgba(pf.$color-primary-new, 1);
      border-color: pf.$color-primary-new;
    }

    &._is-popup {
      padding: 20px 10px;
    }
  }

  &-Box._active &-Preview {
    opacity: 1;
  }

  &-Box._active &-Title {
    color: pf.$color-primary-new;
  }

  &-Box._active &-Description {
    margin-bottom: 0;
    color: #555;
  }

  &-Box._active &-Indicator {
    color: pf.$color-success;
  }

  &-Preview {
    z-index: 0;
    display: none;
    opacity: 0.8;
    transition: 0.2s;

    @include pf.breakpoint-sm {
      display: block;
      position: relative;
      // height: 70px;
    }

    &._wheel,
    &._coupon {
      margin: 0 auto;
      pointer-events: none;
      cursor: default;
      width: 260px;
      height: 182px;
    }
  }

  &-PreviewWheel {
    display: block;
    transform-origin: 0 0;
    transform: scale(0.217);
    width: 1200px;
    height: 840px;

    iframe {
      transform: unset !important;
      height: 100% !important;
    }
  }

  &-PreviewCoupon {
    display: block;
    transform: scale(0.325);
    transform-origin: 0 0;
    width: 800px;
    height: 560px;

    iframe {
      transform: scale(1);
    }
  }

  &-PreviewBox {
    position: relative;
    margin: 20px auto;
    overflow: hidden;
    text-align: left;

    &._notification {
      @include pf.between-992-1124 {
        transform-origin: 0 0;
      }
    }
  }

  &-PremiumLabel {
    background: #ffa100;
    color: #ffffff;
    position: absolute;
    top: 25px;
    right: -45px;
    padding: 4px 60px;
    text-transform: uppercase;
    transform: rotateZ(45deg);
    @include pf.breakpoint-1300 {
      top: 25px;
      right: -40px;
      padding: 2px 60px;
    }
  }

  & &-Title {
    color: pf.$color-secondary-alt;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    margin: 0 0 5px;
  }

  &-Description {
    color: pf.$color-secondary-alt;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin: 0 0 10px;
    &._premium {
      padding: 0 50px;
      @include pf.breakpoint-1024 {
        padding: 0 30px;
      }
    }
  }

  &-Buttons {
    transition: opacity 0.2s;

    .Button {
      margin: 0 5px 5px;
      min-width: 125px;
      @include pf.breakpoint-sm {
        width: auto;
      }

      ion-icon {
        font-size: 22px;
        vertical-align: sub;
        margin-right: 3px;
      }
    }

    &._locked {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  &-Indicator {
    color: pf.$separator;
    font-size: 24px;
    line-height: 24px;
    position: absolute;
    top: 10px;
    left: 10px;
  }
}
