@mixin custom-scrollbar($scrollBarBG: #f7f9fc, $thumbBg: #babec8) {
  & {
    scrollbar-width: thin;
    scrollbar-color: $thumbBg $scrollBarBG;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: $scrollBarBG;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumbBg;
    border-radius: 6px;
    // border: 2px solid $scrollBarBG;
  }
}
