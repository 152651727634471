@use 'proof-factor/import' as pf;

.Integration {

  &Title {
    margin-bottom: 10px;
  }


  &Buttons-Wrapper {
    display: flex;
    flex-flow: row;
    &._bordered {
      border: 1px solid #e6edf3;
      width: calc(100% + 40px);
      margin-left: -20px;
    }
  }

  &Buttons-Tabs {
    display: flex;
    flex-flow: column;
    background-color: #f6f6f7;
  }

  &Buttons-Tab {
    width: 141px;
    margin-right: -1px;
    font-weight: 500;
    font-size: 16px;
    opacity: 0.8;
    text-transform: capitalize;
    padding: 10px 20px;
    z-index: 1;
    border-left: 3px solid transparent;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
    &._active {
      opacity: 1;
      color: var(--color-primary-new);
      background-color: #fff;
      border-bottom: 1px solid #ebeef2;
      border-top: 1px solid #ebeef2;
      border-left: 3px solid var(--color-primary-new);
    }
    &:first-child {
      border-top: none;
    }

    @include pf.breakpoint-380 {
      width: 141px;
      font-size: 14px;
      padding: 20px;
    }
  }

  &Buttons {
    display: flex;
    flex-flow: row wrap;
    padding: 20px 30px;
    border-left: 1px solid #ebeef2;
    @include pf.breakpoint-380 {
      padding: 10px;
    }
  }

  &Button {
    margin: 5px;
    width: 200px;
    min-height: 75px;
    @include pf.breakpoint-500 {
      width: 100%;
    }
    &._blank {
      // pointer-events: none;
      font-size: 13px;
    }

    &:hover {
      border-color: var(--color-primary-new) !important;
      background-color: var(--color-primary-new) !important;
      .IntegrationIcon {
        filter: invert(1);
      }
    }
  }

  &Icon {
    display: inline-block;
    vertical-align: middle;
    width: 22px;
    height: 22px;
    background: no-repeat center center;
    background-size: contain;

    .IntegrationButton & {
      display: block;
      margin: 0 auto 5px !important;

      &:hover {
        border-color: var(--color-primary-new);
        background-color: var(--color-primary-new);
      }
    }

    &._mailchimp {
      background-image: url('/app_assets/mail-integration-icons/mailchimp.png');
    }

    &._omnisend {
      background-image: url('/app_assets/mail-integration-icons/omnisend.svg');
    }

    &._klaviyo {
      background-image: url('/app_assets/mail-integration-icons/klaviyo.png');
    }

    &._activecampaign,
    &._active_campaign {
      background-image: url('/app_assets/mail-integration-icons/active_campaign.svg');
      filter: brightness(0%);
    }

    &._constantcontact {
      background-image: url('/app_assets/mail-integration-icons/constantcontact.png');
    }

    &._drip,
    &._getdrip {
      background-image: url('/app_assets/mail-integration-icons/drip.svg');
    }

    &._zapier {
      background-image: url('/app_assets/mail-integration-icons/zapier.png');
    }

    &._cmcommerce,
    &._cm_commerce {
      background-image: url('/app_assets/mail-integration-icons/cm_commerce.svg');
    }

    &._zoho {
      background-image: url('/app_assets/mail-integration-icons/zoho.png');
    }

    &._aweber {
      background-image: url('/app_assets/mail-integration-icons/aweber.svg');
    }

    &._get_response,
    &._getresponse {
      background-image: url('/app_assets/mail-integration-icons/get_response.png');
    }

    &._shopifyemail {
      background-image: url('/app_assets/mail-integration-icons/shopify_email.svg');
    }

    &._shopify_email {
      background-image: url('/app_assets/mail-integration-icons/shopify.png');
    }

    &._bronto,
    &._brontomodel {
      background-image: url('/app_assets/mail-integration-icons/bronto.png');
    }

    &._sendinblue {
      background-image: url('/app_assets/mail-integration-icons/brevo.svg');
      // background-image: url('/app_assets/mail-integration-icons/sendinblue.png');
    }

    &._mailerlite {
      background-image: url('/app_assets/mail-integration-icons/mailerlite.png');
    }

    &._smartrmail {
      background-image: url('/app_assets/mail-integration-icons/smartrmail.png');
    }

    &._seguno {
      background-image: url('/app_assets/mail-integration-icons/seguno.png');
    }

    &._smsbump {
      background-image: url('/app_assets/mail-integration-icons/smsbump.svg');
    }
  }
}
