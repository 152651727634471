@use 'proof-factor/import' as pf;

.GiftCardOnboarding-Layout {
  // General layout
  display: grid;
  width: 100%;
  position: relative;
  height: 100%;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr auto;
  gap: 10px;
  grid-template-areas: 'aside content'
                       'none footer';
  padding-left: 100px;
  transition: 0.3s ease-in-out;
  @include pf.breakpoint-600 {
    padding-left: 0;
    grid-template-rows: 190px 1fr 70px;
    grid-template-columns: 1fr;
    grid-template-areas: 'aside'
                         'content'
                         'footer';
  }



  &.sidebar-pinned {
    padding-left: 200px;
    @include pf.breakpoint-600 {
      padding-left: 0;
    }
  }

  &-Aside {
    height: 100%;
    position: absolute;
    @include pf.breakpoint-600 {
      position: relative;
    }
  }

  &-Content {
    position: relative;
    grid-area: content;
    place-self: start;
    overflow-y: auto;
    width: 100%;
    //min-width: 500px;
    // max-width: 900px;
    min-height: 50vh;
    max-height: 100%;
    z-index: 1;
    margin: 0 auto;
    @include pf.custom-scrollbar();
  }

  &-Footer {
    position: relative;
    grid-area: footer;
    width: 100%;
    z-index: 2;
    align-self: end;

    .Card {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      // max-width: 900px;
      margin: auto;
      padding: 10px 20px;
    }
  }

  // Step layout
  &-Step {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'head'
                         'content'
                         'preview';
    gap: 15px;

    &-Head {
      grid-area: head;
      border-bottom: 1px solid #ebeef2;
      text-align: center;

      h2 {
        color: var(--color-primary);
      }
    }

    &-Content {
      width: 100%;
      // max-width: 500px;
      place-self: center;
      justify-items: center;
    }

    &-Preview {
      grid-area: preview;
      place-self: center;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.GiftCardOnboarding-ConfigCard {
  border-radius: 1px;
  margin-bottom: 15px;
  overflow: hidden;
  box-shadow: 4px 4px 8px rgba(189, 136, 136, 0.08);

  &-Head {
    position: relative;
    align-items: center;
    font-size: 14px;
    padding: 10px;
    color: #333333;
    background-color: #fff;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    transition: 0.2s;
    box-shadow: rgb(0 0 0 / 8%) 0 2px 8px;
    display: flex;
    width: 100%;

    // &._hoverable {
    //   &:hover {
    //     background-color: #0095f7;
    //     color: #fff;∂ç
    //   }
    //   &:hover .card-control img {
    //     filter: brightness(0) saturate(100%) invert(100%)
    //     sepia(1%) saturate(2%) hue-rotate(174deg) brightness(119%) contrast(100%);
    //   }
    //   &:hover ._tooltip {
    //     color: #50d73f;
    //     background: #fff;
    //   }
    // }

    .sticker {
      display: none;
      background-color: var(--control-subtitle-invert-text);
      width: 4px;
      height: 100%;
      margin: -10px;
      position: absolute;
    }

    .badge {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #bbecd9;
      border-radius: 50px;
      font-weight: 400;
      color: #333333;
    }

    .status {
      position: absolute;
      right: 10px;
      top: 5px;
      font-weight: 400;
      line-height: 24px;
      color: #333333;
      &-container {
        display: flex;
        align-items: center;
      }
    }

    &._invert {
      background-color: #fff;
      color:#0095f7;

      .sticker {
        display: block;
      }

      & .card-control img {
        filter: brightness(0) saturate(100%) invert(100%)
        sepia(1%) saturate(2%) hue-rotate(174deg) brightness(119%) contrast(100%);
      }

      & ._tooltip {
        color: #50d73f;
        background: #fff;
      }
    }

    .card-control {
      display: flex;
      align-items: center;
      flex: 1;
      cursor: default;
      font-weight: bold;
      padding-left: 0.25rem;

      & ion-icon {
        font-size: 18px;
        line-height: 18px;
        margin-right: 10px;
        cursor: pointer;
        transition: all 0.15s;
        color: #0095f7;
      }
      & ._rotated {
        transform: rotate(-90deg);
        color: #333333;
      }
    }
  }

  &-BodyTable {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    grid-gap: 10px;
    padding: 10px;
    //min-width: 500px;
  }

  &-BodyTable > div {
    box-shadow: inset 0 -1px 1px rgba(0, 0, 0, 0.12);
    text-align: center;
    padding: 25px 10px;
    display: grid;
    align-items: center;

    @include pf.breakpoint-600 {
      padding: 15px 5px;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    p:nth-child(2) {
      color: #828587;
      font-size: 12px;
      line-height: 18px;
    }
    &.no-shadow {
      box-shadow: none;
    }
    &.deep-bg {
      background: #fcfcfc;
    }
    &._title {
      h3 {
        font-size: 28px;
        font-weight: 700;
        line-height: 42px;
        @include pf.breakpoint-600 {
          font-size: 22px;
          line-height: 32px;
        }
      }
    }
    &._intro {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.GiftCardOnboarding-Copy {
  position: relative;
  width: fit-content;
  min-width: 180px;
  padding: 6px;
  margin: 4px 0;
  border-radius: 6px;
  border: 1px solid #e6e6e6;

  pf-copy-button {
    position: absolute;
    top: 2px;
    right: 2px;
    opacity: 0;
    background: #fff;
  }

  &:hover {
    pf-copy-button {
      opacity: 1;
    }
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.pointer-none {
  pointer-events: none !important;
}

// auto fit height
// .GiftCardOnboarding-Layout-Content {
//   min-height: auto;
// }
