.LanguagePlugin {
  margin: 0 -20px;
  padding: 20px;
  border-bottom: 1px solid var(--separator);
  &-Name {
    font-size: 14px;
    line-height: 20px;
    color: #647d9e;
    text-transform: uppercase;
    font-weight: 700;
  }
  &-Preview {
    position: relative;
    width: 100%;
    z-index: 0;

    &._coupon,
    &._wheel {
      width: 360px;
      height: 252px;
      overflow: hidden;
    }

    &._wheel {
      iframe {
        transform: unset !important;
        height: 100% !important;
      }
    }
  }
}

.CampaignReviewPluginPreviewWheel {
  background-color: red;

  iframe {
    transform: unset !important;
    height: 100% !important;
  }
}
