@use 'proof-factor/import' as pf;

.StatsCard {
  position: relative;
  border-radius: 5px;
  border: 1px solid #ebeef2;
  background: #fff;
  padding: 20px;
  box-shadow: 0 2px 12px 0 #dfe3eb;
  text-align: center;

  @include pf.breakpoint-1380 {
    padding: 10px;
  }

  &-Title {
    white-space: nowrap;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 700;
    @include pf.breakpoint-1380 {
      font-size: 14px;
    }
  }

  &-Hero {
    font-size: 28.8px;
    font-weight: 500;
    @include pf.breakpoint-660 {
      font-size: 24px;
    }

    &-Subtitle {
      font-size: 14px;
      small {
        display: block;
        font-size: 12px;
      }
    }
  }

  &-Value {
    font-size: 25.6px;
    position: relative;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    font-weight: 400;
    @include pf.breakpoint-1380 {
      font-size: 23px;
    }
  }

  &-Tooltip {
    position: absolute;
    top: 5px;
    right: 5px;
    color: var(--color-secondary);
  }
}

